<template>

	<div
		class="mt-30 text-left flex-row"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div
			class="flex-1 bg-white pa-24 radius-20"
		>
			<div>
				<div class="under-line-bbb pb-15 flex-row">
					<h3 class="flex-1 size-px-20">{{ program.title }}</h3>
				</div>

				<div class="container">
					<div class="position-relative">
            <div class="pa-20">
              <DatePicker type="year" :date="search_year" @click="setDate($event)" ></DatePicker>
            </div>
              <div
                  class="position-absolute"
                  style=" right: 0; top: 20px"
              >
                <div class="flex-column radius-20 bg-white" style="width: 120px; border: 1px solid #dadada"
                     :class="{ date_btn:is_on_period }"
                >
                  <button
                      v-if="is_on_period || payment_state_code === ''"

                      class="text-left size-px-14 color-333"
                      style="padding: 7px 0px 7px 20px"
                      @click="getSearch('')"
                  >전체</button>
                  <button
                      v-if="is_on_period || payment_state_code === 'PM00400003'"
                      @click="getSearch('PM00400003')"
                      class="text-left size-px-14 color-333"
                      style="padding: 7px 0px 7px 20px; border-top: 1px solid #dadada; border-bottom: 1px solid #dadada"
                  >결제완료</button>
                  <button
                      v-if="is_on_period || payment_state_code === 'PM00400006'"
                      @click="getSearch('PM00400006')"
                      class="text-left size-px-14 color-333"
                      style="padding: 7px 0px 7px 20px"
                  >결제취소</button>

                  <v-icon
                      v-if="!is_on_period"
                      class="position-absolute" style="right: 10px; top: 7px"
                      @click="is_on_period = true"
                  >mdi-chevron-down</v-icon>
                </div>
              </div>
					</div>

					<div class="subindex_box_1">
            <table
                v-if="items.length > 0"
                class="table"
            >
              <colgroup>
                <col width="120px" />
                <col width="120px" />
                <col width="auto" />
                <col width="120px" />
                <col width="auto" />
                <col width="150px" />
              </colgroup>
              <thead>
              <tr>
                <th>구분</th>
                <th>구입처</th>
                <th>결제상품</th>
                <th>결제수단</th>
                <th>결제금액(수량)</th>
                <th>거래일시(취소일시)</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(history, h_index) in page_items"
                  :key="'history_' + h_index"
                  class="under-line-not-last pb-20 mb-20"
                  @click="onDetail(history)"
              >
                <td>
                  <div class="color-red" v-if="history.payment_canceldate">취소</div>
                  <div class="color-blue" v-else>결제</div>
                </td>
                <td>
                  <div>{{ history.sl_type_name }}</div>
                </td>
                <td>
                  <div class="text-left">{{ history.nft_sl_name }}</div>
                </td>
                <td>
                  <div>{{ history.payment_mthd_name }}</div>
                </td>
                <td class="text-right">
                  <div v-if="history.payment_mthd_code === 'PM01000003'">{{ Number(history.payment_amount) }}원</div>
                  <div v-else>{{ history.payment_quantity | toFixed(8) }}</div>
                </td>
                <td>
                  <div v-if="history.payment_canceldate">{{ history.payment_canceldate }}</div>
                  <div v-else>{{ history.pmtdate }}</div>
                </td>
              </tr>
              </tbody>
            </table>
						<Empty
							v-else
							class="pt-150"
							:text="text"
						></Empty>
            <Pagination
                v-if="items.length > 0"
                :options="item_search"
                @click="setPagination"
            ></Pagination>
					</div>
				</div>
			</div>
		</div>
    <PopupLayer
        v-if="is_on_detail"
    >
      <template
          v-slot:body
      >
        <div
            class="width-500 ma-auto bg-white radius-20 full-height flex-column"
        >
          <div
              class="size-px-20 text-center font-weight-bold under-line pb-20
					bg-mafia-blue flex-row justify-space-between items-center pa-13-30 pr-20 position-relative"
          >
            <h4 class="color-white" v-if="!item_detail.cancel_date">결제 상세 내역</h4>
            <span class="color-white" v-else>취소 상세 내역</span>
            <h4 class="popup-close">
              <img class="cursor-pointer"	@click="is_on_detail = false"	:src="require('@/assets/image/web/notify_cartel/ico_close.svg')"/>
            </h4>
          </div>

          <div
              class="size-px-14 full-height flex-column"
          >
            <div class="pa-30">
              <div class="pb-10">
                <div class="size-px-16 font-weight-bold">정보</div>
                <div class="mt-10 justify-space-between">
                  <span>구입처</span>
                  <span>{{ item_payment.common_info.sl_type_name }}</span>
                </div>
                <div class="mt-10 justify-space-between">
                  <span>결제상품</span>
                  <span style="color: #3E47B7;">{{ item_payment.common_info.nft_sl_name }}</span>
                </div>
              </div>
              <div class="mt-10 under-line pb-10">
                <div class="size-px-16 font-weight-bold">결제정보</div>
                <div class="mt-10 justify-space-between">
                  <span>거래번호</span>
                  <span>{{ item_detail.transaction_number }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.quantity">
                  <span>결제수단</span>
                  <span>{{ item_detail.methods }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.amount">
                  <span>카드</span>
                  <span>{{ item_detail.card_name }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.approval_number">
                  <span>승인번호</span>
                  <span>{{ item_detail.approval_number }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.installment">
                  <span>거래종류</span>
                  <span>{{ installment }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.quantity">
                  <span v-if="!item_detail.cancel_date">결제수량</span>
                  <span v-else>취소수량</span>
                  <span>{{ item_detail.quantity | toFixed(8) }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.amount">
                  <span v-if="!item_detail.cancel_date">결제금액</span>
                  <span v-else>취소금액</span>
                  <span>{{ item_detail.amount | makeComma }}원</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="!item_detail.cancel_date">
                  <span>할인</span>
                  <span>{{ item_detail.discount | toFixed(8) }}<span v-if="item_detail.amount">원</span></span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.last_quantity && !item_detail.cancel_date">
                  <span>최종결제수량</span>
                  <span style="color: #3E47B7;">{{ item_detail.last_quantity | toFixed(8) }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.last_amount && !item_detail.cancel_date">
                  <span>최종결제금액</span>
                  <span style="color: #3E47B7;">{{ item_detail.last_amount | makeComma }}원</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="!item_detail.cancel_date">
                  <span>거래일시</span>
                  <span>{{ item_detail.date }}</span>
                </div>
                <div class="mt-10 justify-space-between" v-if="item_detail.cancel_date">
                  <span>취소일시</span>
                  <span>{{ item_detail.cancel_date }}</span>
                </div>
              </div>
              <div class="mt-10 pb-10" v-if="item_detail.amount">
                <div class="size-px-16 font-weight-bold">판매자 정보</div>
                <div class="mt-10 justify-space-between">
                  <span>판매자상호</span>
                  <span>주식회사 메타쿱</span>
                </div>
                <div class="mt-10 justify-space-between">
                  <span>대표자명</span>
                  <span>김우정</span>
                </div>
                <div class="mt-10 justify-space-between">
                  <span>사업자등록번호</span>
                  <span>174811-0122632</span>
                </div>
                <div class="mt-10 justify-space-between">
                  <span>주소</span>
                  <span>경상북도 경산시 둥지로 18길 19, 301호</span>
                </div>
              </div>
              <div class="mt-10 pb-10">
                <div class="size-px-16 font-weight-bold">NFT내역</div>
                <div class="mt-10 justify-space-between">
                  <span>NFT</span>
                  <div class="overflow-y-auto" style="max-height: 100px; width: 50%; text-align: end;">
                    <p v-for="(item, index) in item_detail.nft_list"
                       :key="'item_' + index"
                       style="text-decoration-line: underline;"
                    >
                      {{ item.cartl_nft_name }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div
              class="text-center pb-30"
          >
            <button
                class="btn-primary pa-13-30 size-px-14 font-weight-500 radius-20 line-height-1"
                style="outline: none;"
                @click="offPayment"
            >확인
            </button>
          </div>
        </div>
      </template>
    </PopupLayer>
	</div>
</template>

<script>

import Empty from "@/view/Layout/Empty";
import MypageSide from "@/view/Mypage/mypage-lnb";
import DatePicker from "@/components/DatePicker.vue";
import PopupLayer from "@/view/Layout/PopupLayer.vue";
import Pagination from "@/components/Pagination.vue";
export default {
	name: 'NftPaymentHistory'
	, components: {DatePicker, MypageSide, Empty, PopupLayer, Pagination}
	, props: ['user']
	, data: function(){
		return {
			program: {
				name: this.$language.notice.add
				, title: 'NFT 결제 내역'
				, not_footer: true
				, not_header: true
				, type: 'mypage'
				, bg_contents: 'bg-gray01'

			}
      , item_search: {
        page: 1
        , page_cnt: 10
        , total_count: ''
      }
      , items: []
      , page_items: []
      , item_payment: []
      , is_on_detail: false
      , item_detail: {
        transaction_number: ''
        , methods: ''
        , card_name: ''
        , amount: ''
        , quantity: ''
        , discount: ''
        , last_amount: ''
        , last_quantity: ''
        , date: ''
        , approval_number: ''
        , installment: ''
        , cancel_date: ''
        , state_code: ''
        , nft_list: []
      }
      , search_year: ''
      , payment_state_code: ''
			, tab_type: 'in'
			, text:'NFT 결제 내역이 없습니다.'
      , is_on_period: false
		}
	}
  , computed: {
    installment: function () {
      let t = '-'
      if (this.item_detail.installment > 0) {
        t = this.item_detail.installment + '개월'
      } else {
        t = '일시불'
      }
      return t
    }
  }
	, methods: {
    getData: async function () {
      try {
        this.$bus.$emit('on', true)
        const result = await this.$request.init({
          method: 'post'
          , url: this.$api_url.api_path.get_nft_payment_history
          , data: {
            member_number: this.user.member_number
            , search_year: this.search_year + ''
            , payment_state_code: this.payment_state_code
          }
          , type: true
        })

        if (result.success) {
          console.log(result.result.data.d.nft_payment_particulars_list, 'nft history')
          this.items = result.result.data.d.nft_payment_particulars_list
          if(this.items) {
            this.item_search.total_count = this.items.length
          }
          this.setPagination(this.item_search.page)
        } else {
          throw result.message
        }
      } catch (e) {
        console.log(e)
        this.$bus.$emit('notify', {type: 'error', message: e})
      } finally {
        this.$bus.$emit('on', false)
      }
    }
    , getSearch: function (data) {
      this.is_on_period = false
      if(this.payment_state_code !== data){
        this.item_search.page = 1
        this.payment_state_code = data
        this.getData()
      }
    }
    , setPagination: function (page=1){
      this.item_search.page = page
      let items = []
      let page_i = (page - 1) * 10
      for (let i = page_i; i < page_i + 10; i++) {
        if(this.items[i]){
          items.push(this.items[i])
        }
      }
      this.page_items = items
    }
    , resetDetail: function () {
      this.item_detail = {
        transaction_number: ''
        , methods: ''
        , card_name: ''
        , amount: ''
        , quantity: ''
        , discount: ''
        , last_amount: ''
        , last_quantity: ''
        , date: ''
        , approval_number: ''
        , installment: ''
        , cancel_date: ''
        , state_code: ''
        , nft_list: []
      }
    }
    , onDetail: async function (item) {
      this.resetDetail()
      try {
        this.$bus.$emit('on', true)
        const result = await this.$request.init({
          method: 'post'
          , url: this.$api_url.api_path.get_nft_payment_detail
          , data: {
            member_number: this.user.member_number
            , nft_payment_number: item.nft_payment_number
          }
          , type: true
        })

        if (result.success) {
          console.log(result.result.data.d)
          this.is_on_detail = true
          this.item_payment = result.result.data.d
          if (this.item_payment.coin_payment_info.transaction_number) {
            this.item_detail = {
              transaction_number: this.item_payment.coin_payment_info.transaction_number
              , methods: this.item_payment.coin_payment_info.payment_mthd_name
              , discount: this.item_payment.coin_payment_info.dc_quantity
              , quantity: this.item_payment.coin_payment_info.payment_quantity
              , last_quantity: this.item_payment.coin_payment_info.last_payment_quantity
              , date: this.item_payment.coin_payment_info.payment_daytime
              , cancel_date: this.item_payment.coin_payment_info.payment_ccl_daytime
              , state_code: this.item_payment.coin_payment_info.payment_state_code
              , nft_list: this.item_payment.common_info.payment_nft_list
            }
          } else if (this.item_payment.leglter_payment_info.transaction_number) {
            this.item_detail = {
              transaction_number: this.item_payment.leglter_payment_info.transaction_number
              , methods: this.item_payment.leglter_payment_info.payment_mthd_name
              , card_name: this.item_payment.leglter_payment_info.card_name
              , discount: Number(this.item_payment.leglter_payment_info.dc_amount)
              , amount: Number(this.item_payment.leglter_payment_info.payment_amount)
              , last_amount: Number(this.item_payment.leglter_payment_info.last_payment_amount)
              , date: this.item_payment.leglter_payment_info.payment_daytime
              , cancel_date: this.item_payment.leglter_payment_info.payment_ccl_daytime
              , state_code: this.item_payment.leglter_payment_info.payment_state_code
              , approval_number: this.item_payment.leglter_payment_info.approval_number
              , installment: this.item_payment.leglter_payment_info.installment_months_count
              , nft_list: this.item_payment.common_info.payment_nft_list
            }
          } else if (this.item_payment.point_payment_info.transaction_number) {
            this.item_detail = {
              transaction_number: this.item_payment.point_payment_info.transaction_number
              , methods: this.item_payment.point_payment_info.payment_mthd_name
              , discount: Number(this.item_payment.point_payment_info.dc_quantity)
              , quantity: Number(this.item_payment.point_payment_info.payment_quantity)
              , last_quantity: Number(this.item_payment.point_payment_info.last_payment_quantity)
              , date: this.item_payment.point_payment_info.payment_daytime
              , cancel_date: this.item_payment.point_payment_info.payment_ccl_daytime
              , state_code: this.item_payment.point_payment_info.payment_state_code
              , nft_list: this.item_payment.common_info.payment_nft_list
            }
          }
          // this.item_payment.order_number = item.charg_payment_number
        } else {
          throw result.message
        }
      } catch (e) {
        console.log(e)
        this.$bus.$emit('notify', {type: 'error', message: e})
      } finally {
        this.$bus.$emit('on', false)
      }
    }
    , offPayment: function () {
      this.is_on_detail = false
      this.item_payment = {}
    }
    , setDate: function (date) {
      this.search_year = date
      this.getData()
    }
  }
  , created() {
    this.$emit('onLoad', this.program)
    this.getData()
  }
}
</script>
<style>
.elipsis-2{
	width: 260px;
	display: -webkit-box;
	word-wrap: break-word;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
}
</style>